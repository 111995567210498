import React, { useContext, useEffect, useRef, useState } from 'react';
import './Header.scss'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useNavigate } from 'react-router-dom';
import { SocketContext } from '../SocketContext/SocketContext';
import { Post } from '../../Config/config';
import SnackBarNotification from '../SnackBarNotification/SnackBarNotification';
import Loader from '../Loader/Loader';
import Reminder from '../Reminder/Reminder';
import { getFcodeByPath } from '../GlobalFunction/GlobalFunction';

function Header(props) {
    const userData = JSON.parse(localStorage.getItem("userData"));

    const socket = useContext(SocketContext);
    // SankBar
    const [sankbar, setSankbar] = useState(false);
    const [sankbarText, setSankbarText] = useState("");
    const [sankbarColor, setSankbarColor] = useState("");
    // Loader
    const [loader, setLoader] = useState(false);
    const op = useRef(null);
    const op2 = useRef(null);
    const navigator = useNavigate();

    const [fullaName, setFullName] = useState();
    const [emailId, setEmailId] = useState();
    const [shortName, setShortName] = useState();
    const [Id, setId] = useState();
    const [userId, setuserId] = useState();

    const addSale = userData?.Permissions?.find((item) => item.F_Code === "BTNSL")
    const addPurchase = userData?.Permissions?.find((item) => item.F_Code === "BTNPR")
    const addMore = userData?.Permissions?.find((item) => item.F_Code === "BTNMO")
    const porfileper = userData?.Permissions?.find((item) => item.F_Code === "PFL")

    const logout = () => {
        // socket.emit('logout', { UserId: userId?.UserId, CompanyCode: userId?.CompanyCode, Token: Id, EUser: userId?.EUser });
        setLoader(true)
        Post('User/LogOut', {
            UserId: userId?.UserId,
        }).then((res) => {
            setLoader(false)
            if (res) {
                if (res.status === 1) {
                    sessionStorage.clear();
                    localStorage.clear();
                    navigator('/login')
                }
            }
        })
    };

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const Id = localStorage.getItem("ID");

        if (userData !== null && userData !== undefined) {
            setId(Id);
            setuserId(userData);
            setFullName(userData.Username);
            setEmailId(userData.UserEmail);
            setShortName(userData.Username?.substring(0, 1));
        } else {
            console.error('User data or ID is missing in localStorage');
        }

        socket.on('logoutSuccess', (data) => {
            if (data.status === 1) {
                navigator('/login');
            }
        });

        socket.on('logoutError', (data) => {
            console.error('Error logging out:', data.message);
        });

        return () => {
            socket.off('logoutSuccess');
            socket.off('logoutError');
        };
    }, [socket]);


    // useEffect(() => {
    //     const userData = JSON.parse(localStorage.getItem("userData"));
    //     if (userData !== null || undefined) {
    //         setFullName(userData.Username);
    //         setEmailId(userData.UserEmail);

    //         setShortName(userData.Username?.substring(0, 1));
    //     }
    // }, []);

    // profile
    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const modalRef2 = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (modalRef2.current && !modalRef2.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        const handleScroll = () => {
            setIsOpen(false);
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
            window.addEventListener('scroll', handleScroll);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isOpen]);

    const handleNavigation = (route) => {
        if (op2.current) {
            op2.current.hide();
        }
        navigator(route);
    };

    return (
        <div id='Header' className={`${props.siderOpen ? "headerOpen" : "headerColse"}`}>
            <div className='main'>
                <div className='leftHeader'>
                    {/* <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-search"></i>
                        </span>
                        <InputText placeholder="Search transactions" />
                    </div> */}
                </div>
                <div className='rightHeader'>
                    <div className='button'>
                        {addSale?.is_View === true &&
                            <Button label="Add Sale" className='add_sale' onClick={() => { navigator(`/addSaleInvoices/${getFcodeByPath("addSaleInvoices")}`); }} icon="pi pi-plus-circle" severity="success" outlined />
                        }

                        {addPurchase?.is_View === true &&
                            <Button label="Add Purchase" className='add_purchase' onClick={() => { navigator(`/addPurchaseBills/${getFcodeByPath("addPurchaseBills")}`); }} icon="pi pi-plus-circle" severity="danger" outlined />
                        }

                        {addMore?.is_View === true &&
                            <Button label="Add More" icon="pi pi-plus-circle" onClick={(e) => op2.current.toggle(e)} />
                        }

                        <OverlayPanel ref={op2}>
                            <div className='addmore'>
                                <div className='addmore-box'>
                                    <div className='sale'>
                                        <h4>SALES</h4>
                                        <div className='sale-box'>
                                            <div className='sale-line' onClick={() => { navigator(`/addSaleInvoices/${getFcodeByPath("addSaleInvoices")}`); }}>
                                                <div className='sale-icon'>
                                                    <i className="pi pi-sort-up-fill" style={{ transform: 'rotateZ(90deg)' }} ></i>
                                                    <p>Sale Invoice</p>
                                                </div>
                                                <p className='key'>ALT + S</p>
                                            </div>
                                            <div className='sale-line' >
                                                <div className='sale-icon'>
                                                    <i className="pi pi-sort-up-fill" style={{ transform: 'rotateZ(90deg)' }} ></i>
                                                    <p>Sale Order</p>
                                                </div>
                                                <p className='key'>ALT + F</p>
                                            </div>
                                            <div className='sale-line' >
                                                <div className='sale-icon'>
                                                    <i className="pi pi-sort-up-fill" style={{ transform: 'rotateZ(90deg)' }} ></i>
                                                    <p>Sale Return</p>
                                                </div>
                                                <p className='key'>ALT + R</p>
                                            </div>
                                            <div className='sale-line' >
                                                <div className='sale-icon'>
                                                    <i className="pi pi-sort-up-fill" style={{ transform: 'rotateZ(90deg)' }} ></i>
                                                    <p>Payment In</p>
                                                </div>
                                                <p className='key'>ALT + I</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sale'>
                                        <h4>PURCHASE</h4>
                                        <div className='sale-box'>
                                            <div className='sale-line' >
                                                <div className='sale-icon'>
                                                    <i className="pi pi-sort-up-fill" style={{ transform: 'rotateZ(90deg)' }} ></i>
                                                    <p>Purchase Bill</p>
                                                </div>
                                                <p className='key'>ALT + P</p>
                                            </div>
                                            <div className='sale-line' >
                                                <div className='sale-icon'>
                                                    <i className="pi pi-sort-up-fill" style={{ transform: 'rotateZ(90deg)' }} ></i>
                                                    <p>Purchase Return</p>
                                                </div>
                                                <p className='key'>ALT + L</p>
                                            </div>
                                            <div className='sale-line' >
                                                <div className='sale-icon'>
                                                    <i className="pi pi-sort-up-fill" style={{ transform: 'rotateZ(90deg)' }} ></i>
                                                    <p>Payment Out</p>
                                                </div>
                                                <p className='key'>ALT + O</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sale'>
                                        <h4>OTHERS</h4>
                                        <div className='sale-box'>
                                            <div className='sale-line' >
                                                <div className='sale-icon'>
                                                    <i className="pi pi-sort-up-fill" style={{ transform: 'rotateZ(90deg)' }} ></i>
                                                    <p>Add Item</p>
                                                </div>
                                                <p className='key'>ALT + A</p>
                                            </div>
                                            <div className='sale-line'>
                                                <div className='sale-icon'>
                                                    <i className="pi pi-sort-up-fill" style={{ transform: 'rotateZ(90deg)' }} ></i>
                                                    <p>Expenses</p>
                                                </div>
                                                <p className='key'>ALT + E</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='footer'>
                                    <div className='short-cut'>
                                        All shortcut keys in box
                                    </div>
                                </div>
                            </div>
                        </OverlayPanel>
                    </div>

                    <Reminder />

                    <div className="icons">
                        <Button type="button" icon="pi pi-bell" onClick={(e) => op.current.toggle(e)} />
                        <OverlayPanel ref={op}>
                            <div className='notifictions'>
                                <h2>Notifictions</h2>

                                <div className='contain'>
                                    <Button type="button" icon="pi pi-bell" />
                                    <h6>No Notifications yet!</h6>
                                    <p>Stay tuned! Notifications about your activity will show up here.</p>
                                </div>
                            </div>
                        </OverlayPanel>

                        {porfileper?.is_View === true &&
                            <div className='letter_word_click'>
                                <div className={`letter_word ${isOpen ? 'open' : ''}`} onClick={() => handleToggle()}>
                                    <h2>{shortName}</h2>
                                </div>

                                {isOpen && (
                                    <React.Fragment>
                                        <div className='toggleBar' ref={modalRef2}>
                                            <div className='nameBar'>
                                                <div className='letter_word2'>
                                                    <h2>{shortName}</h2>
                                                </div>
                                                <div className="username">
                                                    <h6>{fullaName}</h6>
                                                    <p>{emailId}</p>
                                                </div>
                                            </div>
                                            <nav className='navigation'>
                                                <ul>
                                                    <li onClick={() => { navigator(`/profile/${getFcodeByPath("profile")}`); setIsOpen(false) }}>Profile</li>
                                                    <li onClick={() => { navigator(`/general/${getFcodeByPath("general")}`); setIsOpen(false) }}> Setting </li>
                                                    <li className='hozaderline'></li>
                                                    <li onClick={() => logout()}> Log Out </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        <div className='Backlayer' onClick={() => setIsOpen(false)}></div>
                                    </React.Fragment>
                                )}
                            </div>
                        }
                    </div>

                </div>
            </div>
            <SnackBarNotification
                open={sankbar}
                text={sankbarText}
                color={sankbarColor}
                sankbar={(value) => setSankbar(value)}
            />
            {loader ? <Loader /> : ""}

        </div>
    );
};

export default Header;